import React, { useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { useNavigate } from "react-router-dom";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const bcrypt = require('react-native-bcrypt');

function ResetPassword() {
    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //reset password event
    const [passError, setPassError] = useState('');

    // const msg = window.sessionStorage.getItem("message");

    let navigate = useNavigate();

    function handleResetPassword() { 
        setIsLoading(true);

        const email = document.getElementById('user_email').value;
        const passw = document.getElementById('passw').value;
        const passw_conf = document.getElementById('passw_conf').value;

        var salt = bcrypt.genSaltSync(10);
        var hashedPassw = bcrypt.hashSync(passw, salt);

        var tempCompare = bcrypt.compareSync(passw_conf, hashedPassw); 

        if(tempCompare === true) {

            if(passw.length>=6 && passw_conf.length>=6) {

                const list_obj = {
                    'email': email,
                    'passw': hashedPassw
                }

                fetch(process.env.REACT_APP_REQ_IP + '/reset', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
                    if(data.result === true) {

                        toast.success(data.message, {
                            position: toast.POSITION.TOP_CENTER,
                            closeOnClick: true,
                            theme: "light" //dark
                        });

                        navigate("/app/login", { replace: true });
                    }
                    else {

                        toast.error(data.message, {
                            position: toast.POSITION.TOP_CENTER,
                            closeOnClick: true,
                            theme: "light" //dark
                        });

                        setIsLoading(false);
                    }

                })
                .catch(err => {
                    setIsLoading(false);
                    setError(err);
                });
            }
            else {
                setIsLoading(false);
                setPassError('Şifreniz en az 6 karakter olmalıdır!');
            }
        }
        else {
            setIsLoading(false);
            setPassError('Girdiğiniz şifreler birbiri ile uyuşmamaktadır, lütfen kontrol ediniz!');
        }
    }

    if (isLoading) {
        return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#5500dc" />
          </View>
        );
    }
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <div className="content">
                <div className="container">
                    <div className="row justify-content-center">
                        <ToastContainer />
                        <div className="col-md-6 contents">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <div className="mb-4">
                                        <h3>Şifre Sıfırlama</h3>
                                        <p className="mb-4">Excel E-Fatura Dönüşümü</p>
                                    </div>

                                    <form method="POST" className="mt-4">
                                        <div className="form-group mb-4">
                                            <label htmlFor="user_email" className="mb-1">Email</label>
                                            <input type="email" className="form-control" name="_email" autoComplete="off" id="user_email" placeholder="Kayıtlı emailinizi giriniz"/>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="passw" className="mb-1">Yeni Şifre</label>
                                            <input type="password" className="form-control" name="_passw" autoComplete="off" id="passw" placeholder="Yeni şifre"/>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="passw_conf" className="mb-1">Yeni Şifre Doğrulaması</label>
                                            <input type="password" className="form-control" name="_passw_conf" id="passw_conf" placeholder="Yeni şifre (tekrar)"/>
                                        </div>
                                        <div className="d-flex mb-5 align-items-center">
                                            <span className="mr-auto"><a href="/app/login" className="caption">Giriş Yap</a></span> 
                                            <span className="ml-auto"><a href="/app/register" className="forgot-pass">Kayıt Ol</a></span> 
                                        </div>
                                        <div className="form-group my-3 d-flex flex-column justify-content-center">
                                            <label style={{ color: 'red', fontSize: '0.7em' }} className="mb-3">{passError}</label>
                                            <input className="btn btn-outline-secondary rounded-pill p-2 w-100" onClick={handleResetPassword} type="button" value="Şifremi Sıfırla"/>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetPassword;