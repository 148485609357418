import React from 'react';

import { useNavigate } from "react-router-dom";
import { ReactComponent as FailIcon } from "../assets/svg/fail.svg";

import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import { handleLogout } from '../components/Logout.js';

function Failure() {

    //routing components
    let navigate = useNavigate();

    //if user not authenticated
    if(window.sessionStorage.getItem("uid") === null) {
        handleLogout();
        navigate("/app/login", { replace: true });
    }

    //page check
    if(window.sessionStorage.getItem("ltype") === "admin") {
        handleLogout();
        navigate("/app/login", { replace: true });
    }


    window.sessionStorage.setItem("pageTitle", "Ödeme");

    return (
        <div className="wrapper">
            <Sidebar />
            <div className="main-panel">
                <Navbar />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card ">
                                    <div className="card-header ">
                                        <h1 className="card-title text-center">Ödemeniz başarısız oldu 😞</h1>
                                        <h3 className="card-category text-center">Lütfen tekrar deneyiniz.</h3>
                                    </div>
                                    <div className="card-body">
                                        <FailIcon className="payment-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>

    );

}

export default Failure;