import React, { useState, useEffect } from 'react';
import '../assets/css/auth-style.css';

import { useNavigate } from "react-router-dom";
import { Text, View, ActivityIndicator } from 'react-native';


function Navbar() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [credits, setCredits] = useState(null);

    const pageTitle = window.sessionStorage.getItem("pageTitle");

    function handleLogout(){ 
        window.sessionStorage.removeItem('uid');
        window.sessionStorage.removeItem('email');
        window.sessionStorage.removeItem('fname');
        window.sessionStorage.removeItem('lname');
        window.sessionStorage.removeItem('message');
        window.sessionStorage.removeItem('ltype');

        navigate("/app/login", { replace: true });
    }

    const uid = window.sessionStorage.getItem('uid');

    useEffect(() => {

        const list_obj = {
            "uid": uid
        }

        fetch(process.env.REACT_APP_REQ_IP + '/credits', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);
            if(data.result === true){
                setCredits(data.credits);
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    
    }, [uid]);

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#5500dc" />
            </View>
        );
    }

    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <nav className="navbar navbar-expand-lg " color-on-scroll="500">
                <div className="container-fluid">
                    <h3 className="navbar-brand opti-text-black">{pageTitle}</h3>
                    <div className="collapse navbar-collapse justify-content-end" id="navigation">
                        <ul className="navbar-nav ml-auto">
                            <li className='nav-item m-auto'>
                                <div className='opti-text-danger d-flex align-items-center'>Mevcut Krediniz: <span className="badge badge-danger badge-alignment ml-1"> {credits}</span> <hr className='badge-vert-line'/> </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link link-pointer" onClick={handleLogout}>
                                    <span className="no-icon">Çıkış Yap</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }

}

export default Navbar;