import React, { useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';

import '../assets/css/auth-style.css';
import { useNavigate } from "react-router-dom";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const bcrypt = require('react-native-bcrypt');

function Login() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //login event
    const [passError, setPassError] = useState('');

    //login buttons
    const [loginBtn, setLoginBtn] = useState(true);
    const [adminStyle, setAStyle] = useState(["", "", ""]);
    const [userStyle, setUStyle] = useState(["", "", ""]);

    //if user authenticated
    if(window.sessionStorage.getItem("uid") != null) {
        navigate("/app/dash", { replace: true });
    }

    // const msg = window.sessionStorage.getItem("message");

    function handleAdminLogin() {

        setIsLoading(true);

        window.sessionStorage.removeItem('message');

        const email = document.getElementById('user_aemail').value;
        const pass = document.getElementById('user_apassword').value;

        const list_obj = {
            "email": email,
        }
        
        fetch(process.env.REACT_APP_REQ_IP + '/admins', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            if(data.result === false) {
                setIsLoading(false);
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });
            }
            else {
                var tempCompare = bcrypt.compareSync(pass, data.userInfo[0][5]); 

                if(tempCompare === true) {
                    window.sessionStorage.setItem("uid", data.userInfo[0][0]);
                    window.sessionStorage.setItem("email", data.userInfo[0][1]);
                    window.sessionStorage.setItem("fname", data.userInfo[0][3]);
                    window.sessionStorage.setItem("lname", data.userInfo[0][4]);
                    window.sessionStorage.setItem("ltype", "admin");

                    navigate("/app/admin", { replace: true }); 
                }
                else {
                    setIsLoading(false);
                    setPassError("Şifreniz yanlış, lütfen tekrar deneyiniz.");
                }
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
        
    }

    function handleUserLogin() { 
        setIsLoading(true);

        window.sessionStorage.removeItem('message');

        const email = document.getElementById('user_email').value;
        const pass = document.getElementById('user_password').value;

        const list_obj = {
            "email": email,
        }
        
        fetch(process.env.REACT_APP_REQ_IP + '/users', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            if(data.result === false) {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });

                navigate("/app/register", { replace: true });
            }
            else {
                var tempCompare = bcrypt.compareSync(pass, data.userInfo[0][5]); 

                if(tempCompare === true) {
                    window.sessionStorage.setItem("uid", data.userInfo[0][0]);
                    window.sessionStorage.setItem("email", data.userInfo[0][1]);
                    window.sessionStorage.setItem("fname", data.userInfo[0][3]);
                    window.sessionStorage.setItem("lname", data.userInfo[0][4]);
                    window.sessionStorage.setItem("address", data.userInfo[0][12]);
                    window.sessionStorage.setItem("company", data.userInfo[0][11]);
                    window.sessionStorage.setItem("vkn", data.userInfo[0][10]);
                    
                    window.sessionStorage.setItem("ltype", "user");

                    navigate("/app/dash", { replace: true }); 
                }
                else {
                    setIsLoading(false);
                    setPassError("Şifreniz yanlış, lütfen tekrar deneyiniz.");
                }
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    }

    function handleUserButton() {
        setUStyle(["white", "#1DC7EA", "#1DC7EA"]);
        setAStyle(["#1DC7EA", "white", "#1DC7EA"]);
        setLoginBtn(true);
    }

    function handleAdminButton() {
        setAStyle(["white", "#1DC7EA", "#1DC7EA"]);
        setUStyle(["#1DC7EA", "white", "#1DC7EA"]);
        setLoginBtn(false);
    }

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#5500dc" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <div className="content">
                <div className="container">
                    <div className="row justify-content-center">
                        {/* <div className="col-md-6">
                            <LoginImg className="img-fluid" />
                        </div> */}
                        <ToastContainer />
                        <div className="col-md-6 contents">
                            <div className="row justify-content-center">
                                <div className="col-md-8">

                                    <div className="btn-group w-100 mb-3" role="group" aria-label="Basic example">
                                        <button type="button" className="btn login-user-button w-50" style={{color: userStyle[0], backgroundColor: userStyle[1], borderColor: userStyle[3]}} onClick={handleUserButton}>Kullanıcı</button>
                                        <button type="button" className="btn login-ad-button w-50" style={{color: adminStyle[0], backgroundColor: adminStyle[1], borderColor: adminStyle[3]}} onClick={handleAdminButton}>Admin</button>
                                    </div>

                                    {loginBtn && 
                                        <span>
                                            <div className="mb-4">
                                                <h3>Kullanıcı Girişi</h3>
                                                <p className="mb-4">Excel E-Fatura Dönüşümü</p>
                                                {/* <div style={{ color: 'red', fontSize: '0.7em' }}>{msg}</div> */}
                                            </div>
                                            <form method="POST">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="email" id="user_email" placeholder="Email" required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" className="form-control" name="password" id="user_password" placeholder="Şifre" required />
                                                    <span style={{ color: 'red', fontSize: '0.7em' }}>{passError}</span>
                                                </div>
            
                                                <div className="d-flex mb-5 align-items-center">
                                    
                                                    <span className="mr-auto"><a href="/app/register" className="caption">Şimdi kayıt ol?</a></span> 
                                                    <span className="ml-auto"><a href="/app/reset" className="forgot-pass">Şifremi unuttum</a></span> 
                                                </div>
            
                                                <input type="button" value="Giriş Yap" className="btn btn-block btn-dark" onClick={handleUserLogin}/>
            
                                            </form>
                                        </span>
                                    }

                                    {!loginBtn && 
                                        <span>
                                            <div className="mb-4">
                                                <h3>Admin Girişi</h3>
                                                <p className="mb-4">Excel E-Fatura Dönüşümü</p>
                                                {/* <div style={{ color: 'red', fontSize: '0.7em' }}>{msg}</div> */}
                                            </div>
                                            <form method="POST">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="email" id="user_aemail" placeholder="Email" required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" className="form-control" name="password" id="user_apassword" placeholder="Şifre" required />
                                                    <span style={{ color: 'red', fontSize: '0.7em' }}>{passError}</span>
                                                </div>

                                                <div className="d-flex mb-5 align-items-center">

                                                    <span className="mr-auto"><a href="/app/register" className="caption">Şimdi kayıt ol?</a></span> 
                                                    <span className="ml-auto"><a href="/app/reset" className="forgot-pass">Şifremi unuttum</a></span> 
                                                </div>

                                                <input type="button" value="Giriş Yap" className="btn btn-block btn-dark" onClick={handleAdminLogin}/>

                                            </form>
                                        </span>
                                    }

                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        );
    }

}

export default Login;