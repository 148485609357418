import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Text, View, ActivityIndicator } from 'react-native';

import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { JsonToExcel } from "react-json-to-excel";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { handleLogout } from '../components/Logout.js';

function Dashboard() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [uploadFile, setUploadFile] = useState();
    const [objFile, setObjFile] = useState(null);
    const [btnstatus, setBtnstatus] = useState(false);

    const [xlsxType, setXlsxType] = useState("Satır Bazlı");
    const [invoiceType, setInvoiceType] = useState("Alış");

    const [modalStatus, setModalStatus] = useState(false);

    window.sessionStorage.setItem("pageTitle", "Anasayfa");

    const uid = window.sessionStorage.getItem('uid');

    //if user not authenticated
    if(window.sessionStorage.getItem("uid") === null) {
        handleLogout();
        navigate("/app/login", { replace: true });
    }

    //page check
    if(window.sessionStorage.getItem("ltype") === "admin") {
        handleLogout();
        navigate("/app/login", { replace: true });
    }

    const toogle = () => setModalStatus(!modalStatus);

    const xlsxTypeChange = (e) => {
        var typeFormat = e.target.value;
        setXlsxType(typeFormat);
    }

    const invoiceTypeChange = (e) => {
        var typeFormat = e.target.value;
        setInvoiceType(typeFormat);
    }

    const handleZipFile = (event) => {
        const zip_size = event.target.files[0].size;

        const zip_size_mb = (zip_size / (1024*1024));
        if(zip_size_mb > 1000) {
            toast.error("Dosyanız 1 GB'ın altında olmalıdır. Yüklemeye çalıştığınız dosya boyutu" + String(zip_size_mb.toFixed(3)) + " MB. Lütfen boyutu daha küçük bir .zip dosyası yükleyiniz." , {
                position: toast.POSITION.TOP_CENTER,
                closeOnClick: true,
                theme: "light" //dark
            });
            setBtnstatus(false);
        }
        else {
            setUploadFile(event.target.files[0]);
            setBtnstatus(true);
        }
	};

    const handleCreate = () => {
        setModalStatus(!modalStatus);
    }


    function handleSendZip() { 
        setIsLoading(true);

        fetch(process.env.REACT_APP_REQ_IP + '/upload_file?ref=' + uid + '&itype=' + invoiceType + '&ixlsx=' + xlsxType, { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/zip'}, body: uploadFile}).then(res => res.json()).then(data => {

            setIsLoading(false);
            setModalStatus(!modalStatus);

            if(data.result === false && data.payment_code === false){
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });

                navigate("/app/credit", { replace: true });
            }
            else if(data.result === false) {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });
            }
            else {
                toast.success(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });

                data = JSON.parse(data.result_df);

                setObjFile(data);
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    }

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#5500dc" />
            </View>
        );
    }

    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Navbar />
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <ToastContainer />
                                <div className="col-md-12">
                                    <div className="card ">
                                        <div className="card-header ">
                                            <h4 className="card-title">Xml dosyalarınızı .zip olarak yükleyiniz</h4>
                                            <p className="card-category text-warning d-flex align-items-center mt-1"><i className="nc-icon nc-bell-55 mr-1"></i>Dosyalarınızın .zip ile bittiğinden emin olunuz.</p>
                                            <p className="card-category text-warning d-flex align-items-center"><i className="nc-icon nc-bell-55 mr-1"></i>Dosya boyutunuz 1GB'dan büyük olmamalıdır.</p>
                                        </div>
                                        <div className="card-body">
                                            <div className="App">
                                                <label>Zip dosyası seçiniz</label>
                                                <input
                                                    onChange={handleZipFile}                
                                                    type="file"
                                                    accept=".zip"
                                                    className='d-flex mb-4'
                                                    name='file'
                                                />

                                                <div className='radio-period-group' onChange={(e) => xlsxTypeChange(e)}>
                                                    <input className="form-check-input" type="radio" id="linebased" name="axlsx" value="Satır Bazlı" defaultChecked/>
                                                    <label className='text-capitalize figure-font-size2' htmlFor="linebased">Satır bazlı xlsx</label><br/>

                                                    <input className="form-check-input" type="radio" id="invoicebased" name="axlsx" value="Fatura Bazlı"/>
                                                    <label className='text-capitalize figure-font-size2' htmlFor="invoicebased">Fatura bazlı xlsx</label> 
                                                </div>  


                                                <div className='radio-period-group' onChange={(e) => invoiceTypeChange(e)}>
                                                    <input className="form-check-input" type="radio" id="purchase" name="ainv" value="Alış" defaultChecked/>
                                                    <label className='text-capitalize figure-font-size2' htmlFor="purchase">Alış faturası</label><br/>

                                                    <input className="form-check-input" type="radio" id="sales" name="ainv" value="Satış"/>
                                                    <label className='text-capitalize figure-font-size2' htmlFor="sales">Satış Faturası</label> 
                                                </div>  

                                                {
                                                    btnstatus && 
                                                        <button className='btn btn-dark my-2' onClick={handleCreate}>
                                                        Dosyayı yükle
                                                        </button>
                                                }

                                                {
                                                    !btnstatus && 
                                                        <button className='btn btn-dark my-2' onClick={handleCreate} disabled>
                                                        Dosyayı yükle
                                                        </button>
                                                }

                                                <Modal isOpen={modalStatus} className="">
                                                    <ModalHeader><span className='font-weight-bold text-info'>Yüklemekte olduğunuz dosya için seçimlerinizi onaylayınız</span></ModalHeader>
                                                    <ModalBody>

                                                    {invoiceType && xlsxType && 
                                                        <span>
                                                            <b>{invoiceType}</b> faturası yüklemiş olduğunuzu ve <b>{xlsxType}</b> olarak excel çıktısı almak istediğinizi görüntülüyoruz. 
                                                            İşleme devam edilmesini onaylıyor musunuz?
                                                        </span>
                                                    }
                                                        
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="secondary" style={{ cursor: 'pointer'}} onClick={toogle}>Düzenle</Button>
                                                        <input type="button" style={{ cursor: 'pointer'}}  value="Onayla" onClick={handleSendZip} className="btn btn-info btn-fill pull-right"/>
                                                    </ModalFooter>
                                                </Modal>

                                                
                                                {objFile && 
                                                        <JsonToExcel
                                                        title="Sonuçları Excel olarak indir"
                                                        data={objFile}
                                                        fileName="sample-file"
                                                        btnClassName="custom-classname"
                                                      />
                                                }

                                            </div>
                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }

}

export default Dashboard;