import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Packages from './pages/Packages';
import Success from './pages/Success';
import Failure from './pages/Failure';
import Results from './pages/Results';
import ResetPassword from './pages/ResetPass';
import Authentication from './pages/Authentication';
import Profile from './pages/Profile';

import AdminDash from './admin/AdminDash';
import AdminPay from './admin/AdminPay';
import AdminUsage from './admin/AdminUsage';

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";

class App extends React.Component {	
    constructor(props){
      super(props);
    
      this.state = {};
    }
    
    render(){
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />}/> 
            <Route path="/app/" element={<Login />}/> 
            <Route path="/app/register" element={<Register />}/> 
            <Route path="/app/login" element={<Login />}/> 
            <Route path="/app/dash" element={<Dashboard />}/> 
            <Route path="/app/packages" element={<Packages />}/> 
            <Route path="/app/results" element={<Results />}/> 
            <Route path="/app/profile" element={<Profile />}/> 
            <Route path="/app/payment/success" element={<Success />}/> 
            <Route path="/app/payment/failure" element={<Failure />}/> 
            <Route path="/app/reset" element={<ResetPassword/>}/> 
            <Route path="/auth/unverified" element={<Authentication/>}/> 

            <Route path="/app/admin" element={<AdminDash/>}/> 
            <Route path="/app/admin/payments" element={<AdminPay/>}/> 
            <Route path="/app/admin/usage" element={<AdminUsage/>}/> 

            {/* <Route path="*" element={<Login/>}/> */}
          </Routes>
        </BrowserRouter>   
      )
    }
}

render(<App></App>, document.querySelector('#root'));

export default App;

