import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Text, View, ActivityIndicator } from 'react-native';

import Sidebar from './AdminSidebar';
import Footer from '../components/Footer';
import Navbar from './AdminNavbar';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { handleLogout } from '../components/Logout.js';


function AdminDash() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [results, setResults] = useState();
    const [message, setMessage] = useState();

    //if user not authenticated
    if(window.sessionStorage.getItem("uid") === null) {
        handleLogout();
        navigate("/app/login", { replace: true });
    }

    //page checked
    if(window.sessionStorage.getItem("ltype") === "user") {
        handleLogout();
        navigate("/app/login", { replace: true });
    }

    window.sessionStorage.setItem("pageTitle", "Kullanıcılar");
    
    const uid = window.sessionStorage.getItem('uid');

    useEffect(() => {

        const list_obj = {
            "uid": uid
        }

        fetch(process.env.REACT_APP_REQ_IP + '/user_list', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);
            if(data.result === true){

                const res = JSON.parse(data.users);

                res.map((item, index) => {

                    var d = new Date(item.registration_date);
                    var formattedDate = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
                    var hours = (d.getHours() < 10) ? "0" + d.getHours() : d.getHours();
                    var minutes = (d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes();
                    var formattedTime = hours + ":" + minutes;
    
                    formattedDate = formattedDate + " " + formattedTime;

                    item.fdate = formattedDate
                })

                setResults(res);
            }
            else {
                toast.error(data.message , {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });

                handleLogout();
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    
    }, [uid]);


    useEffect(() => {
        const email = window.sessionStorage.getItem('email');

        const list_obj = {
            "email": email,
        }

        fetch(process.env.REACT_APP_REQ_IP + '/admins', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);

            if(data.result === false) {
                toast.error("Bu sayfayı görmek için izinli değilsiniz.", {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });

                handleLogout();
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    
    }, [uid]);

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#5500dc" />
            </View>
        );
    }

    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
            </View>
        );
    }

    else{
        return (
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Navbar />
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <ToastContainer />
                                <div className="col-md-12">
                                    <div className="card strpied-tabled-with-hover">
                                        <div className="card-header ">
                                            <h4 className="card-title">{message}</h4>
                                        </div>
                                        <div className="card-body table-responsive result-table">
                                            {results &&
                                            <table className="table table-hover table-striped admin-tables">
                                                <thead>
                                                    <tr>
                                                        <th>İsim</th>
                                                        <th>Soyisim</th>
                                                        <th>Kayıt Tarihi</th>
                                                        <th>Email</th>
                                                        <th>Email Doğrulama Statüsü</th>

                                                        <th>Şirket Bilgisi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {results.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.name}</td>
                                                        <td>{item.last_name}</td>
                                                        <td>{item.fdate}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.auth_status}</td>

                                                        <td>{item.company}</td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            }         
                                        </div>
                                    </div>                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
    
        );
    }

}

export default AdminDash;