import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { Text, View, ActivityIndicator } from 'react-native';

import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { handleLogout } from '../components/Logout.js';

function Packages() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //if user not authenticated
    if(window.sessionStorage.getItem("uid") === null) {
        handleLogout();
        navigate("/app/login", { replace: true });
    }

    //page check
    if(window.sessionStorage.getItem("ltype") === "admin") {
        handleLogout();
        navigate("/app/login", { replace: true });
    }
    
    window.sessionStorage.setItem("pageTitle", "Kontör Satın Al");

    const uid = window.sessionStorage.getItem('uid');

    function handleBuyCredit(e) { 

        const selected_package = e.target.value;
        
        setIsLoading(true);

        const list_obj = {
            "uid": uid,
            "package": selected_package
        }

        fetch(process.env.REACT_APP_REQ_IP + '/buy_credit', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);

            if(data.result === false){
                window.sessionStorage.setItem("message", data.message);
            }
            else {
                window.location.replace(data.result);
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    }


    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#5500dc" />
            </View>
        );
    }

    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Navbar />
                    <div className="content">
                        <ToastContainer />
                        <div className="container-fluid">
                            <div className="card-deck mb-3 text-center price-card">
                                <div className="card box-shadow col-md-3">
                                    <div className="card-header">
                                        <h4 className="my-0 font-weight-normal text-success">Paket 1</h4>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h2 className="card-title pricing-card-title">150 ₺  </h2>

                                        <ul className="list-unstyled mt-3 mb-4 pricing-ul">
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>200 Kontör</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>Tüm faturayı tek satıra indirgeme ve/veya satır bazlı inceleme</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>.xlsx formatında çıktı</li>
                                        </ul>
                                        <button type="button" value="p1" className="btn btn-lg btn-block btn-outline-primary choose-btn link-pointer" onClick={handleBuyCredit}>Seç</button>
                                    </div>

                                </div>


                                <div className="card box-shadow col-md-3">
                                    <div className="card-header">
                                        <h4 className="my-0 font-weight-normal text-info">Paket 2</h4>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h2 className="card-title pricing-card-title">500 ₺  </h2>

                                        <ul className="list-unstyled mt-3 mb-4 pricing-ul">
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>1.100 Kontör</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>Tüm faturayı tek satıra indirgeme ve/veya satır bazlı inceleme</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>.xlsx formatında çıktı</li>
                                        </ul>
                                        <button type="button" value="p2" className="btn btn-lg btn-block btn-outline-primary choose-btn link-pointer" onClick={handleBuyCredit}>Seç</button>
                                    </div>
                                </div>

                                <div className="card box-shadow col-md-3">
                                    <div className="card-header">
                                        <h4 className="my-0 font-weight-normal text-danger">Paket 3</h4>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h2 className="card-title pricing-card-title">1000 ₺  </h2>

                                        <ul className="list-unstyled mt-3 mb-4 pricing-ul">
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>2.300 Kontör</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>Tüm faturayı tek satıra indirgeme ve/veya satır bazlı inceleme</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>.xlsx formatında çıktı</li>
                                        </ul>
                                        <button type="button" value="p3" className="btn btn-lg btn-block btn-outline-primary choose-btn link-pointer" onClick={handleBuyCredit}>Seç</button>
                                    </div>
                                </div>

                                <div className="card box-shadow col-md-3">
                                    <div className="card-header">
                                        <h4 className="my-0 font-weight-normal text-dark">Paket 4</h4>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h2 className="card-title pricing-card-title">1500 ₺  </h2>

                                        <ul className="list-unstyled mt-3 mb-4 pricing-ul">
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>3.500 Kontör</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>Tüm faturayı tek satıra indirgeme ve/veya satır bazlı inceleme</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>.xlsx formatında çıktı</li>
                                        </ul>
                                        <button type="button" value="p4" className="btn btn-lg btn-block btn-outline-primary choose-btn link-pointer" onClick={handleBuyCredit}>Seç</button>
                                    </div>
                                </div>

                                <div className="card box-shadow col-md-3">
                                    <div className="card-header">
                                        <h4 className="my-0 font-weight-normal text-warning">Paket 5</h4>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h2 className="card-title pricing-card-title">2500 ₺  </h2>

                                        <ul className="list-unstyled mt-3 mb-4 pricing-ul">
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>6.000 Kontör</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>Tüm faturayı tek satıra indirgeme ve/veya satır bazlı inceleme ve/veya satır bazlı inceleme</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>.xlsx formatında çıktı</li>
                                        </ul>
                                        <button type="button" value="p5" className="btn btn-lg btn-block btn-outline-primary choose-btn link-pointer" onClick={handleBuyCredit}>Seç</button>
                                    </div>
                                </div>

                                <div className="card box-shadow col-md-3">
                                    <div className="card-header">
                                        <h4 className="my-0 font-weight-normal text-success">Paket 6</h4>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h2 className="card-title pricing-card-title">3000 ₺  </h2>

                                        <ul className="list-unstyled mt-3 mb-4 pricing-ul">
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>7.500 Kontör</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>Tüm faturayı tek satıra indirgeme ve/veya satır bazlı inceleme</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>.xlsx formatında çıktı</li>
                                        </ul>
                                        <button type="button" value="p6" className="btn btn-lg btn-block btn-outline-primary choose-btn link-pointer" onClick={handleBuyCredit}>Seç</button>
                                    </div>
                                </div>

                                <div className="card box-shadow col-md-3">
                                    <div className="card-header">
                                        <h4 className="my-0 font-weight-normal text-danger">Paket 7</h4>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h2 className="card-title pricing-card-title">3500 ₺  </h2>

                                        <ul className="list-unstyled mt-3 mb-4 pricing-ul">
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>9.000 Kontör</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>Tüm faturayı tek satıra indirgeme ve/veya satır bazlı inceleme</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>.xlsx formatında çıktı</li>
                                        </ul>
                                        <button type="button" value="p7" className="btn btn-lg btn-block btn-outline-primary choose-btn link-pointer" onClick={handleBuyCredit}>Seç</button>
                                    </div>
                                </div>


                                <div className="card box-shadow col-md-3">
                                    <div className="card-header">
                                        <h4 className="my-0 font-weight-normal text-primary">Paket 8</h4>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h2 className="card-title pricing-card-title">4000 ₺  </h2>

                                        <ul className="list-unstyled mt-3 mb-4 pricing-ul">
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>11.000 Kontör</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>Tüm faturayı tek satıra indirgeme ve/veya satır bazlı inceleme</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>.xlsx formatında çıktı</li>
                                        </ul>
                                        <button type="button" value="p8" className="btn btn-lg btn-block btn-outline-primary choose-btn link-pointer" onClick={handleBuyCredit}>Seç</button>
                                    </div>
                                </div>

                                <div className="card box-shadow col-md-3">
                                    <div className="card-header">
                                        <h4 className="my-0 font-weight-normal text-info">Paket 9</h4>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h2 className="card-title pricing-card-title">5.000 ₺  </h2>

                                        <ul className="list-unstyled mt-3 mb-4 pricing-ul">
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>14.000 Kontör</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>Tüm faturayı tek satıra indirgeme ve/veya satır bazlı inceleme</li>
                                            <li><i className="nc-icon nc-check-2 icon-bold mr-2 pricing-tick-icon"></i>.xlsx formatında çıktı</li>
                                        </ul>
                                        <button type="button" value="p9" className="btn btn-lg btn-block btn-outline-primary choose-btn link-pointer" onClick={handleBuyCredit}>Seç</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );

    }

}

export default Packages;