import React, { useState, useEffect } from 'react';
import '../assets/css/auth-style.css';

function Sidebar() {
    const [dashClass, setDashClass] = useState("nav-item active");
    const [paymentClass, setPaymentClass] = useState("nav-item");
    const [usageClass, setUsageClass] = useState("nav-item");

    useEffect(() => {
        if(window.location.pathname === "/app/admin"){
            setDashClass("nav-item active");
            setPaymentClass("nav-item");
            setUsageClass("nav-item");
        }
        else if(window.location.pathname === "/app/admin/payments") {
            setDashClass("nav-item");
            setPaymentClass("nav-item active");
            setUsageClass("nav-item");
        }
        else {
            setDashClass("nav-item");
            setPaymentClass("nav-item");
            setUsageClass("nav-item active");
        }

    },[]); 

    return (
        <div className="sidebar sidebar-background" data-image="../assets/img/abc.png" data-color="azure">
            <div className="sidebar-wrapper">
                <div className="logo">
                    <a href="http://excelefatura.kolay.ai" className="simple-text">
                        <img src="/logo.png" width="120" alt="logo"/>
                    </a>
                </div>
                <ul className="nav">
                    <li className={dashClass}>
                        <a className="nav-link" href="/app/admin">
                            <i className="nc-icon nc-badge"></i>
                            <p className='sidebar-text'>Kullanıcılar</p>
                        </a>
                    </li>
                    <li className={paymentClass}>
                        <a className="nav-link" href="/app/admin/payments">
                            <i className="nc-icon nc-money-coins"></i>
                            <p className='sidebar-text'>Gelen Ödemeler</p>
                        </a>
                    </li>
                    <li className={usageClass}>
                        <a className="nav-link" href="/app/admin/usage">
                            <i className="nc-icon nc-notes"></i>
                            <p className='sidebar-text'>Kontör Kullanımları</p>
                        </a>
                    </li>
                    <li className="nav-item active active-pro">
                        <a className="nav-link active" href="http://excelefatura.com/">
                            <i className="nc-icon nc-alien-33"></i>
                            <p className='sidebar-text'>İLETİŞİM</p>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

    );

}

export default Sidebar;