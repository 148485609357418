import React, { useState, useEffect } from 'react';
import '../assets/css/auth-style.css';

function Sidebar() {
    const [dashClass, setDashClass] = useState("nav-item active");
    const [creditClass, setCreditClass] = useState("nav-item");
    const [resultsClass, setResultsClass] = useState("nav-item");
    const [profileClass, setProfileClass] = useState("nav-item");


    useEffect(() => {
        if(window.location.pathname === "/app/dash"){
            setDashClass("nav-item active");
            setCreditClass("nav-item");
            setResultsClass("nav-item");
            setProfileClass("nav-item");
        }
        else if(window.location.pathname === "/app/results") {
            setDashClass("nav-item");
            setCreditClass("nav-item");
            setResultsClass("nav-item active");
            setProfileClass("nav-item");
        }
        else if(window.location.pathname === "/app/packages"){
            setDashClass("nav-item");
            setCreditClass("nav-item active");
            setResultsClass("nav-item");
            setProfileClass("nav-item");
        }
        else {
            setDashClass("nav-item");
            setCreditClass("nav-item");
            setResultsClass("nav-item");
            setProfileClass("nav-item active");
        }
    
    },[]); 

    return (
        <div className="sidebar sidebar-background" data-image="../assets/img/abc.png" data-color="azure">
            <div className="sidebar-wrapper">
                <div className="logo">
                    <a href="http://excelefatura.kolay.ai" className="simple-text">
                        <img src="/logo.png" width="120" alt="logo"/>
                    </a>
                </div>
                <ul className="nav">
                    <li className={dashClass}>
                        <a className="nav-link" href="/app/dash">
                            <i className="nc-icon nc-chart-pie-35"></i>
                            <p className='sidebar-text'>Anasayfa</p>
                        </a>
                    </li>
                    <li className={creditClass}>
                        <a className="nav-link" href="/app/packages">
                            <i className="nc-icon nc-money-coins"></i>
                            <p className='sidebar-text'>Kontör Satın Al</p>
                        </a>
                    </li>
                    <li className={resultsClass}>
                        <a className="nav-link" href="/app/results">
                            <i className="nc-icon nc-notes"></i>
                            <p className='sidebar-text'>Sonuçlar</p>
                        </a>
                    </li>
                    <li className={profileClass}>
                        <a className="nav-link" href="/app/profile">
                            <i className="nc-icon nc-single-02"></i>
                            <p className='sidebar-text'>Profil</p>
                        </a>
                    </li>
                    <li className="nav-item active active-pro">
                        <a className="nav-link active" href="http://excelefatura.com/">
                            <i className="nc-icon nc-alien-33"></i>
                            <p className='sidebar-text'>İLETİŞİM</p>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

    );

}

export default Sidebar;