import React, { useState, useEffect } from 'react';

import { Text, View, ActivityIndicator } from 'react-native';
import { useNavigate, useLocation } from "react-router-dom";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function Authentication() {

    // //routing components
    let navigate = useNavigate();

    //loading components
    const [error, setError] = useState(null);

    const search = useLocation().search;

    const uid = new URLSearchParams(search).get('uid');
    const ref = new URLSearchParams(search).get('ref');
    const action = new URLSearchParams(search).get('act');

    useEffect(() => {

        let isSubscribed= true;

        const list_obj = {
            'uid': uid,
            'ref': ref,
            'act': action
        }

        fetch(process.env.REACT_APP_REQ_IP + '/auth/unverified', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            if(data.result) {
                toast.success(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });
                navigate("/app/login", { replace: true });
            }
            else {
                //burası gözükmüyor loading simgesinden dolayı
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });

                navigate("/app/register", { replace: true });
            }
        })
        .catch(err => {
            setError(err);
        });

        return () => {
            isSubscribed = false;
        };

    }, []);


    if(error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ToastContainer />
                <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
            </View>
        );
    }

    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ToastContainer />
            <ActivityIndicator size="large" color="#5500dc" />
        </View>
    );

}

export default Authentication;