import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Text, View, ActivityIndicator } from 'react-native';

import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import { JsonToExcel } from "react-json-to-excel";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { handleLogout } from '../components/Logout.js';

function Results() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [results, setResults] = useState();
    const [message, setMessage] = useState();
    const [objFile, setObjFile] = useState(null);

    //if user not authenticated
    if(window.sessionStorage.getItem("uid") === null) {
        handleLogout();
        navigate("/app/login", { replace: true });
    }

    //page check
    if(window.sessionStorage.getItem("ltype") === "admin") {
        handleLogout();
        navigate("/app/login", { replace: true });
    }

    window.sessionStorage.setItem("pageTitle", "Sonuçlar");
    
    const uid = window.sessionStorage.getItem('uid');

    function handleDownload(e){ 
        const req_id = e.target.value;

        const list_obj = {
            "id": req_id,
            "uid": uid
        }

        fetch(process.env.REACT_APP_REQ_IP + '/download', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);
            
            if(data.result === true){

                toast.success(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });

                data = JSON.parse(data.analyses);

                results.map((item, index) => {
                    if(item.id === req_id) {
                        item.obj = data;
                        setObjFile(data);
                    }
                    else {
                        item.obj = null;
                    }
                })

                setResults(results);
                
            }
            else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });

        
    }

    useEffect(() => {

        const list_obj = {
            "uid": uid
        }

        fetch(process.env.REACT_APP_REQ_IP + '/analyses', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);
            setMessage(data.message);
            if(data.result === true){

                const res = JSON.parse(data.analyses);

                res.map((item, index) => {

                    var d = new Date(item.date);
                    var formattedDate = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
                    var hours = (d.getHours() < 10) ? "0" + d.getHours() : d.getHours();
                    var minutes = (d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes();
                    var formattedTime = hours + ":" + minutes;
    
                    formattedDate = formattedDate + " " + formattedTime;

                    item.fdate = formattedDate
                    if(item.status === "active") {
                        item.dis = false
                        item.color="#1DC7EA"
                    }
                    else {
                        item.dis = true
                        item.color="#7f7f82"
                    }

                })

                setResults(res);
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    
    }, [uid]);

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#5500dc" />
            </View>
        );
    }

    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
            </View>
        );
    }

    else{
        return (
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Navbar />
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <ToastContainer />
                                <div className="col-md-12">
                                    <div className="card strpied-tabled-with-hover">
                                        <div className="card-header ">
                                            <h4 className="card-title">{message}</h4>
                                        </div>
                                        <div className="card-body table-full-width table-responsive">
                                            {results &&
                                            <table className="table table-hover table-striped">
                                                <thead>
                                                    <th>Tarih</th>
                                                    <th>Fatura Sayısı</th>
                                                    <th>Satır Sayısı</th>
                                                    <th>Kontör</th>
                                                    <th>Durum</th>
                                                    <th>Fatura Tipi</th>
                                                    <th>Çıktı Dosyası Tipi</th>
                                                    <th>...</th>
                                                </thead>
                                                <tbody>
                                                    {results.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.fdate}</td>
                                                        <td>{item.file_count}</td>
                                                        <td>{item.line_count}</td>
                                                        <td>{item.request_credit}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.invoice_type}</td>
                                                        <td>{item.xlsx_type}</td>

                                                        <td>
                                                            {
                                                                item.dis && 
                                                                <button className='btn no-pointer' type="button" style={{ color: '#7f7f82'}} disabled>Sonuçları getir</button>
                                                            }
                                                            {
                                                                !item.dis && !item.obj &&
                                                                <button className='btn btn-primary btn-fill link-pointer' type="button" value={item.id} onClick={handleDownload}>Sonuçları getir</button>
                                                            }
                                                            {
                                                                !item.dis && item.obj &&

                                                                    <JsonToExcel
                                                                        title="Sonuçları Excel olarak indir"
                                                                        data={objFile}
                                                                        fileName="sample-file"
                                                                        btnClassName="btn btn-success btn-fill"
                                                                    />
                                                            }
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            }         
                                        </div>
                                    </div>                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
    
        );
    }

}

export default Results;