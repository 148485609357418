import React, { useState, useEffect } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { useNavigate } from "react-router-dom";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

function Profile() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //if user not authenticated
    if(window.sessionStorage.getItem("uid") == null) {
        navigate("/app/login", { replace: true });
    }

    const email = window.sessionStorage.getItem("email");
    const fname = window.sessionStorage.getItem("fname");
    const lname = window.sessionStorage.getItem("lname");
    const address = window.sessionStorage.getItem("address");
    const company = window.sessionStorage.getItem("company");
    const vkn = window.sessionStorage.getItem("vkn");

    window.sessionStorage.setItem("pageTitle", "Profile");
    const msg = window.sessionStorage.getItem("message");

    function updateProfile() { 
        setIsLoading(true);

        window.sessionStorage.removeItem('message');

        const user_lname = document.getElementById('user_lname').value;
        const user_fname = document.getElementById('user_fname').value;
        const user_address = document.getElementById('user_address').value;
        const user_company = document.getElementById('user_company').value;
        const user_vkn = document.getElementById('user_vkn').value;

        const update_list_obj = {
            "user_lname": user_lname,
            "user_fname": user_fname,
            "user_address": user_address,
            "user_company": user_company,
            "user_vkn": user_vkn,
            "email": email
        }

        fetch(process.env.REACT_APP_REQ_IP + '/update_user', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(update_list_obj)}).then(res => res.json()).then(data => {

            if(data.result === true) {
                window.sessionStorage.setItem("fname", user_fname);
                window.sessionStorage.setItem("lname", user_lname);
                window.sessionStorage.setItem("address", user_address);
                window.sessionStorage.setItem("company", user_company);
                window.sessionStorage.setItem("vkn", user_vkn);

                toast.success(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });
            }
            else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: true,
                    theme: "light" //dark
                });
            }

            setIsLoading(false);
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    }

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#5500dc" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>Error: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <Navbar />
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <ToastContainer />
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Profil Bilgilerini Düzenle</h4>
                                        </div>
                                        <div className="card-body">
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-12 pr-1">
                                                        <div className="form-group">
                                                            <label>Email Adresi</label>
                                                            <input type="email" className="form-control" placeholder="Email" id="user_email" value={email} disabled/>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div className="row">
                                                    <div className="col-md-6 pr-1">
                                                        <div className="form-group">
                                                            <label>İsim</label>
                                                            <input type="text" className="form-control" placeholder="İsim" id="user_fname" defaultValue={fname} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 pl-1">
                                                        <div className="form-group">
                                                            <label>Soyisim</label>
                                                            <input type="text" className="form-control" placeholder="Soyisim" id="user_lname" defaultValue={lname}/>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Adres</label>
                                                            <input type="text" className="form-control" placeholder="Adres" id="user_address" defaultValue={address}/>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Şirket İsmi</label>
                                                            <input type="text" className="form-control" placeholder="Şirket" id="user_company" defaultValue={company}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>VKN</label>
                                                            <input type="text" className="form-control" placeholder="VKN" id="user_vkn" defaultValue={vkn}/>
                                                        </div>
                                                    </div>
                                                </div>
                        
                                                <input type="button" value="Bilgileri Güncelle" className="btn btn-info btn-fill pull-right" onClick={updateProfile}/>
    
                                                <div className="clearfix"></div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
    
                                <div className="col-md-4">
                                    <div className="card card-user">
                                        <div className="card-image">
                                            <img src="/side-1.png" alt="profile-background"/>
                                        </div>
                                        <div className="card-body">
                                            <div className="author">
                                                <a href="#">
                                                    <img className="avatar border-gray" src="/sd.png" alt="..."/>
                                                    <h5 className="title">{fname} {lname}</h5>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );

    }

}

export default Profile;