import React, { useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { useNavigate } from "react-router-dom";
import validator from 'validator'

import '../assets/css/auth-style.css';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const bcrypt = require('react-native-bcrypt');

function Register() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //register event
    const [emailError, setEmailError] = useState('');
    const [passError, setPassError] = useState('');

    const msg = window.sessionStorage.getItem("message");

    const validateEmail = (e) => {
        var email = e.target.value;

        if (validator.isEmail(email)) {
          setEmailError('Geçerli email adresi ✓');
        } 
        else {
          setEmailError('Lütfen geçerli bir email giriniz!');
        }
    }
    
    const validatePass = (e) => {
        var pass = e.target.value;

        if (pass.length>=6) {
            setPassError('Şifre geçerli ✓');
        } 
        else {
            setPassError('Şifreniz en az 6 karakter olmalıdır!');
        }
    }

    function handleRegister() {

        setIsLoading(true);

        window.sessionStorage.removeItem("message");

        const fname = document.getElementById('user_fname').value;
        const lname = document.getElementById('user_lname').value;
        const email = document.getElementById('user_email').value;
        const passw = document.getElementById('user_password').value;
        const secretque = document.getElementById('user_secretque').value;
        const que_answer = document.getElementById('user_que_answer').value;
        const vkn = document.getElementById('user_vkn').value;
        const company = document.getElementById('user_comp').value;
        const address = document.getElementById('user_address').value;

        var salt = bcrypt.genSaltSync(10);
        var hashedPass = bcrypt.hashSync(passw, salt);

        if(!validator.isEmail(email)) {
            setIsLoading(false);
            window.sessionStorage.setItem("message", 'Lütfen geçerli bir email giriniz!');
        }
        else if(!passw.length>=6) {
            setIsLoading(false);
            window.sessionStorage.setItem("message", 'Şifreniz en az 6 karakter olmalıdır!');
        }
        else {

            const list_obj = {
                "email": email
            }

            const register_list_obj = {
                "email": email,
                "pass": hashedPass,
                "name": fname,
                "lastname": lname,
                "que": secretque,
                "que_answer": que_answer,
                "vkn": vkn,
                "company": company,
                "address": address
            }

            fetch(process.env.REACT_APP_REQ_IP + '/users', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {

                //kullanıcı yoksa
                if(data.result === false) {
                  fetch(process.env.REACT_APP_REQ_IP + '/register', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(register_list_obj)}).then(res => res.json()).then(data => {
                    
                    //register işlemi başarılıysa
                    if(data.result === true) {
                        toast.success(data.message, {
                            position: toast.POSITION.TOP_CENTER,
                            closeOnClick: true,
                            theme: "light" //dark
                        });
                        navigate("/app/login");
                    }
                    //register işlemi failse
                    else{
                        setIsLoading(false);
                        toast.error(data.message, {
                            position: toast.POSITION.TOP_CENTER,
                            closeOnClick: true,
                            theme: "light" //dark
                        });
                    }
                  })
                }
                //kullanıcı varsa
                else { 
                    toast.info(data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        closeOnClick: true,
                        theme: "light" //dark
                    });
                    navigate("/app/login");
                }
            })
            .catch(err => {
                setIsLoading(false);
                setError(err);
            });
        }

    }

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#5500dc" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <div className="mt-4">
                <div className="container-fluid d-flex justify-content-center">
                    {/* <div className="row justify-content-center"> */}
                        <ToastContainer />
                        <div className="col-md-4">
                            {/* <div className="row justify-content-center"> */}
                                {/* <div className="col-md-8"> */}
                                    <div className="mb-4">
                                        <h3>Kayıt Ol</h3>
                                        <p className="mb-4">Optimist for Artificial Intelligence, Wisdom of Science</p>
                                        {/* <div style={{ color: 'red', fontSize: '0.7em' }}>{msg}</div> */}
                                    </div>
                                    <form onSubmit={handleRegister}>
                                        <div className='form-group d-flex justify-content-between'>
                                            <div>
                                                <input type="text" className="form-control" name="fname" placeholder="İsim" id="user_fname" required />
                                            </div>
                                            <div>
                                                <input type="text" className="form-control" name="lname" placeholder="Soyisim" id="user_lname" required />
                                            </div>
                                        </div>

                                        <p>Gizlilik sorusu</p>

                                        <div className="form-group">
                                            <input type="email" className="form-control" name="email" placeholder="Email" id="user_email" required onChange={(e) => validateEmail(e)}/>
                                            <span style={{ color: 'red', fontSize: '0.7em' }}>{emailError}</span>
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control" name="password" placeholder="Şifre" id="user_password" required onChange={(e) => validatePass(e)}/>
                                            <span style={{ color: 'red', fontSize: '0.7em' }}>{passError}</span>
                                        </div>

                                        <select className="custom-select mb-3" name="question" id="user_secretque" required>
                                            <option value="">Seçiniz</option>
                                            <option value="1">Annenizin evlenmeden önceki soyadı nedir?</option>
                                            <option value="2">Babanızın doğum tarihi nedir?</option>
                                            <option value="3">En sevdiğiniz renk nedir?</option>
                                            <option value="4">En sevdiğiniz tarihi kişilik kimdir?</option>
                                            <option value="5">Eşinizle hangi şehirde tanıştınız?</option>
                                            <option value="6">Okuduğunuz ilkokulun adı nedir?</option>
                                            <option value="7">Çocukluk kahramanınızın adı nedir?</option>
                                            <option value="8">İlk arabanızın markası nedir?</option>
                                            <option value="9">İlk evcil hayvanınız nedir?</option>
                                        </select>

                                        <div className="form-group">
                                            <input type="text" className="form-control" name="que_answer" placeholder="Cevabınız" id="user_que_answer" required/>
                                        </div>

                                        <p>Fatura için detaylar (Eğer kontör alınırsa gerekli olacaktır)</p>

                                        <div className="form-group">
                                            <input type="text" className="form-control" name="vkn" placeholder="Vergi numarası" id="user_vkn"/>
                                        </div>

                                        <div className='form-group d-flex justify-content-between'>
                                            <div>
                                                <input type="text" className="form-control" name="fcomp" placeholder="Şirket ismi" id="user_comp" required />
                                            </div>
                                            <div>
                                                <input type="text" className="form-control" name="faddress" placeholder="Adres" id="user_address" required />
                                            </div>
                                        </div>
    
                                        <div className="form-group mb-1">
                                            <label>
                                            <input type="checkbox" required/> 
                                            <small> 
                                                <a className='text-decoration-none' href="https://optiwisdom.com/privacy-policy/"> Yasal Bildirimin Koşullarını </a> ve
                                                <a className='text-decoration-none' href="https://optiwisdom.com/eula/"> Son Kullanıcı Sözleşmesini</a> kabul ediyorum.
                                                <sup style={{ color: 'red' }}>*</sup></small>    
                                            </label>
                                        </div>

                                        <div className="d-flex mt-4 mb-1 align-items-center">
                                            <span className="mr-auto question-registered-title">Zaten üye misiniz? <a href="/app/login" className="caption">Giriş yapın</a></span> 
                                        </div>
    
                                        {/* <input type="submit" value="Sign Up" className="btn btn-block btn-dark" style={{ pointerEvents: btnstatus }} onClick={handleRegister}/> */}
                                        <input type="submit" value="Kayıt Ol" className="btn btn-block btn-dark"/>
    
                                    </form>

                                {/* </div> */}
                            {/* </div> */}
                        </div> 
                    </div>
                </div>
            
        );
    }


}

export default Register;