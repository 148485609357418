import React from 'react';
import '../assets/css/auth-style.css';

function Footer() {

    return (
        <footer className="footer">
            <div className="container-fluid">
                <nav>
                    <p className="copyright text-center">
                        ©2022 By <a href="">OptiWisdom</a> 
                    </p>
                </nav>
            </div>
        </footer>
    );

}

export default Footer;